import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ChildAccountTable from './ChildAccountTable';
import CustomerCard from './CustomerCard';
import CustomerSearch from './CustomerSearch';

const ParentAccountModal = ({
  showModal,
  setShowModal,
  parentAccount,
  updateParentAccount,
}) => {
  const [childAccounts, setChildAccounts] = useState([]);

  const [parentCardCustomer, setParentCardCustomer] = useState(null);

  useEffect(() => {
    if (parentAccount) {
      setParentCardCustomer(parentAccount);
    }
  }, [parentAccount]);

  const loadChildAccounts = async () => {
    const children = [];
    const childAccounts = parentAccount.fields.childAccounts || [];
    for (const childAccountId of childAccounts) {
      const childCustomer = await axios.get(
        process.env.REACT_APP_API_URL + '/customers/' + childAccountId,
      );
      children.push(childCustomer.data);
    }
    setChildAccounts(children);
  };

  const updateData = (accounts, customer) => {
    let updatedParentAccount, updatedChildAccount;
    for (const rec of accounts) {
      if (rec.id === parentAccount.id) {
        updatedParentAccount = rec;
      } else if (rec.id === customer.id) {
        updatedChildAccount = rec;
      }
    }
    updateParentAccount(updatedParentAccount);
    setParentCardCustomer(updatedParentAccount);

    const updatedChildAccounts = [];
    const parentChildAccounts =
          updatedParentAccount.fields.childAccounts || [];
    for (const childId of parentChildAccounts) {
      if (childId === updatedChildAccount.id) {
        updatedChildAccounts.push(updatedChildAccount);
      } else {
        for (const child of childAccounts) {
          if (child.id === childId) {
            updatedChildAccounts.push(child);
          }
        }
      }
    }

    setChildAccounts(updatedChildAccounts);
  };

  const addToParentAccount = async (customer, parentAccount) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + '/customers/addToParentAccount',
        {
          parentId: parentAccount.id,
          childId: customer.id,
        },
      );
      updateData(res.data, customer);
    } catch (error) {
      Bugsnag.notify(error);
      console.log(error);
    }
  };

  const removeFromParentAccount = async (customer, parentAccount) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + '/customers/removeFromParentAccount',
        {
          parentId: parentAccount.id,
          childId: customer.id,
        },
      );
      updateData(res.data, customer);
    } catch (error) {
      Bugsnag.notify(error);
      console.log(error);
    }
  };

  const transferHours = async (childAccount, hours, remove) => {
    try {
      let parentId = parentAccount.id;
      let childId = childAccount.id;
      if (remove) {
        parentId = childAccount.id;
        childId = parentAccount.id;
      }
      const relatedSubscriptionId =
        parentAccount.fields.relatedSubscriptions &&
        parentAccount.fields.subscriptionStatus[0] === 'active'
          ? parentAccount.fields.relatedSubscriptions[0]
          : null;
      const res = await axios.post(
        process.env.REACT_APP_API_URL + '/customers/transferHours',
        {
          parentId: parentId,
          childId: childId,
          hours: hours,
          relatedSubscriptionId: relatedSubscriptionId
        },
      );
      updateData(res.data, childAccount);
    } catch (error) {
      Bugsnag.notify(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (parentAccount) {
      loadChildAccounts();
    }
  }, [parentAccount]);

  return (
    <Modal size="xl" show={showModal} backdrop="static">
      <Modal.Header>
        <Modal.Title>Parent Account Management</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          <CustomerCard customer={parentCardCustomer} />
          {childAccounts && childAccounts.length > 0 ? (
            <div>
              <h3 className="text-center">Linked Accounts</h3>
              <ChildAccountTable
                childAccounts={childAccounts}
                parentAccount={parentAccount}
                removeFromParentAccount={removeFromParentAccount}
                transferHours={transferHours}
              />
            </div>
          ) : (
            <>
              <p className="text-center">No linked accounts found</p>
            </>
          )}
          <hr className="w-100 border border-2 opacity-50" />
          <CustomerSearch
            parentAccount={parentAccount}
            addToParentAccount={addToParentAccount}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ParentAccountModal;
