import { Card, Carousel, Container, Form, Table } from 'react-bootstrap';
import { useState } from 'react';
import AsyncButton from './AsyncButton';
import axios from 'axios';
import { dateFormat } from '../utils/format';
import CustomerCard from './CustomerCard';
import Bugsnag from '@bugsnag/js';

const CustomerSearch = ({ parentAccount, addToParentAccount, addChat }) => {
  const [lookupType, setLookupType] = useState('email');
  const [errorMessage, setErrorMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [customers, setCustomers] = useState([]);
  const [activeCustomer, setActiveCustomer] = useState(null);
  const [activeCustomerIndex, setActiveCustomerIndex] = useState(0);
  const selectLookup = (value) => {
    setLookupType(value);
  };
  const search = async () => {
    try {
      if (!searchTerm) {
        setErrorMessage('Please enter a search term');
      }

      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          '/customers/' +
          lookupType +
          '/' +
          searchTerm,
      );
      const customerRes = res.data;

      if (customerRes) {
        if (lookupType === 'number') {
          setActiveCustomer(customerRes)
          setCustomers([customerRes]);
          setErrorMessage('');
        } else {
          setActiveCustomer(customerRes[0])
          setCustomers(customerRes);
          setErrorMessage('');
        }
      } else {
        setErrorMessage('Client not found');
      }
    } catch (error) {
      console.log(error);
      Bugsnag.notify(error);
      setErrorMessage('Error searching for Client');
    }
  };

  const addToParent = async () => {
    await addToParentAccount(activeCustomer, parentAccount);
    setCustomer(null);
  };

  return (
    <>
      {!parentAccount ? (
        <h1>Client Lookup</h1>
      ) : (
        <h5>Search for client to add</h5>
      )}
      <div className="d-flex flex-row mt-4">
        <p className="pe-4">Lookup by: </p>
        <Form.Check
          inline
          label="Email"
          name="searchType"
          type="radio"
          id="email-radio"
          onChange={() => selectLookup('email')}
          checked={lookupType === 'email'}
        />
        <Form.Check
          inline
          label="Number"
          name="searchType"
          type="radio"
          id="number-radio"
          onChange={() => selectLookup('number')}
          checked={lookupType === 'number'}
        />
      </div>
      <div className="d-flex flex-row">
        <Form.Control
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={
            lookupType === 'email' ? 'Client Email' : 'Client Number'
          }
        />
        <AsyncButton onClick={search} buttonClass="btn btn-primary ms-2">
          Search
        </AsyncButton>
      </div>
      {errorMessage && <p className="text-danger pt-2">{errorMessage}</p>}
      {customers.length > 0 && (
        <Table striped bordered hover className="mt-4 w-75">
          <thead>
            <tr>
              <th>Client Number</th>
              <th>Client Name</th>
              <th>Client Email</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer, index) => (
              <tr
                key={customer.id}
                className={activeCustomer.id === customer.id ? 'table-active fw-bold' : ''}
                onClick={() => setActiveCustomer(customer)}
              >
                <td>{customer.fields.number}</td>
                <td>{customer.fields.name}</td>
                <td>{customer.fields.email}</td>
              </tr>
            ))}
          </tbody>

          </Table>
      )}
      {activeCustomer && (
        <CustomerCard customer={activeCustomer} parentAccount={parentAccount} />
      )}
      {activeCustomer &&
        parentAccount &&
        !activeCustomer.fields.isParentAccount &&
        !activeCustomer.fields.parentAccount && (
          <AsyncButton onClick={addToParent} buttonClass="">
            Add to Parent Account
          </AsyncButton>
        )}
      {activeCustomer && addChat && (
        <AsyncButton
          onClick={() => addChat(activeCustomer)}
          buttonClass="btn btn-primary ms-2"
        >
          Add Chat
        </AsyncButton>
      )}
    </>
  );
};

export default CustomerSearch;
