import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav';
import CustomerSearch from './CustomerSearch';
import { useState } from 'react';
import { useFirebase } from '../firebase';
import ParentAccounts from './ParentAccounts';
import { Container } from 'react-bootstrap';
import Chat from './Chat';

const Dashboard = () => {
  const {logoutUser} = useFirebase();
  const [tab, setTab] = useState('customerLookup');
  return (
    <>
      <Navbar bg="secondary" className="px-4">
        <Navbar.Brand href="/admin">TULA Admin Dashboard</Navbar.Brand>
        <Navbar.Collapse>
          <Nav className="me-auto">
            <Nav.Link onClick={() => setTab('customerLookup')} className={tab === 'customerLookup' ? 'font-weight-bold active': ''}>Client Lookup</Nav.Link>
            <Nav.Link onClick={() => setTab('parentAccounts')} className={tab === 'parentAccounts' ? 'font-weight-bold active': ''}>Parent Account Management</Nav.Link>
            <Nav.Link onClick={() => setTab('chat')} className={tab === 'chat' ? 'font-weight-bold active': ''}>Chat</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Nav.Link onClick={logoutUser}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>

      </Navbar>
    <Container className="d-flex flex-column justify-content-center align-items-center pt-4">
      
      {tab === 'customerLookup' && <CustomerSearch />}
      {tab === 'parentAccounts' && <ParentAccounts />}
      {tab === 'chat' && <Chat showControls={true} />}
      </Container>
    </>
  );
}

export default Dashboard