import Bugsnag from '@bugsnag/js';
import { faAdd, faChevronDown, faChevronUp, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { dateFormat } from '../utils/format';
import AsyncButton from './AsyncButton';
import CustomerSubscription from './CustomerSubscription';
import HoursModal from './HoursModal';
import SubscriptionsModal from './SubscriptionsModal';
import TodosModal from './TodosModal';


const CustomerCard = ({ customer, parentAccount }) => {

  

  useEffect(() => {
    setCardCustomer(customer);
    getCustomerTodos();
  }, [customer])

  const [cardCustomer, setCardCustomer] = useState(customer);
  const [showHoursModal, setShowHoursModal] = useState(false);
  const [showSubscriptionsModal, setShowSubscriptionsModal] = useState(false);
  const [showTodosModal, setShowTodosModal] = useState(false);
  const [activeTodos, setActiveTodos] = useState([]);
  const [todoData, setTodoData] = useState([]);
  const [todosLoaded, setTodosLoaded] = useState(false);

  const [notesOpen, setNotesOpen] = useState(false);
  const [addNote, setAddNote] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    if (notesOpen) {
      if (customer.fields.customerNotes && customer.fields.customerNotes.length > 0) {
        if (notes.length === 0) {
          getCustomerNotes();
        }
      }
    }
  }, [notesOpen])

  const getCustomerNotes = async () => {
    try {
      console.log('here')
      const res = await axios.get(
        process.env.REACT_APP_API_URL + '/customers/notes/' + customer.fields.number
      )
      if (res.data) {
        // sort by createdAt
        res.data.sort((a, b) => new Date(b.fields.createdAt) - new Date(a.fields.createdAt));
        setNotes(res.data);
      }
    } catch (error) {
      Bugsnag.notify(error)
      console.log(error);
    }
  }

  const saveNote = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + '/customers/notes/',
        {
          customerId: customer.id,
          note: noteText
        }
      )
      if (res.data) {
        setNotes([res.data[0], ...notes]);
        setAddNote(false);
        setNoteText('');
      }
    } catch (error) {
      Bugsnag.notify(error)
      console.log(error);
    }
  }

  const getCustomerTodos = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + '/customers/todos/' + customer.fields.number,
      );
      if (res.data) {
        const data = {
          unsubmitted: [],
          submitted: [],
          inProgress: [],
          completed: [],
          cancelled: [],
          all: res.data
        }
        for (const todo of res.data) {
          if (todo.fields.listStatus === 'unsubmitted') {
            data.unsubmitted.push(todo);
          } else if (todo.fields.listStatus === 'submitted') {
            data.submitted.push(todo);
          } else if (todo.fields.listStatus === 'inProgress') {
            data.inProgress.push(todo);
          } else if (todo.fields.listStatus === 'completed') {
            data.completed.push(todo);
          } else if (todo.fields.listStatus === 'cancelled') {
            data.cancelled.push(todo);
          }
        }
        setTodoData(data)
        setTodosLoaded(true);
      }
    } catch (error) {
      Bugsnag.notify(error)
      console.log(error);
    }
  }

  const makeParentAccount = async () => {
    try {
      const res = await axios.put(
        process.env.REACT_APP_API_URL + '/customers/setParentAccount/' + customer.id,
      );
      ;
      if (res.data) {
        setCardCustomer(res.data);
      }
    } catch (error) {
      Bugsnag.notify(error)
      console.log(error);
    }
  };

  if (!cardCustomer) {
    return <></>;
  }

  return (
    <Card className="my-5" style={{ width: '24rem' }}>
      <Card.Img
        variant="top"
        height={200}
        style={{ objectFit: 'contain' }}
        src={
          cardCustomer.fields.pictureUrl
            ? cardCustomer.fields.pictureUrl[0]
            : 'https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg'
        }
      />
      <Card.Body className="text-center">
        <Card.Title>
          {cardCustomer.fields.name}
          <br /># {cardCustomer.fields.number}
        </Card.Title>
        <Card.Text>
          <span className="fs-6">{cardCustomer.fields.email}</span>
        </Card.Text>
        <Card.Text>
          {cardCustomer.fields.isParentAccount ? (
            <span className="fw-bold m-0">Parent Account</span>
          ) : !cardCustomer.fields.parentAccount ? (
            <AsyncButton onClick={makeParentAccount} size="sm">
              Make Parent Account
            </AsyncButton>
          ) : (
            <span className="fw-bold m-0 fs-6">
              Child of Client #{cardCustomer.fields.parentAccountNumber}
            </span>
          )}
        </Card.Text>
        <Card.Text>
          <a
            onClick={() => setShowHoursModal(true)}
            className="fw-bold m-0"
            style={{ cursor: 'pointer' }}
          >
            Hours:
          </a>
          <br />
          {cardCustomer.fields.hoursBalance}
        </Card.Text>
        <Card.Text>
          <a
            onClick={() => setShowSubscriptionsModal(true)}
            className="fw-bold m-0"
            style={{ cursor: 'pointer' }}
          >
            Subscription:
          </a>
          <br />
          <CustomerSubscription customer={cardCustomer} />
        </Card.Text>
        <Card.Text>
          <a
            onClick={() => setShowTodosModal(true)}
            className="fw-bold m-0"
            style={{ cursor: 'pointer' }}
          >
            TO-DOs:
          </a>
          <br />
          {todosLoaded ? (
            <>
              <span>{todoData.unsubmitted.length} Unsubmitted</span>
              <br />
              <span>{todoData.submitted.length} Submitted</span>
              <br />
              <span>{todoData.inProgress.length} In Progress</span>
              <br />
              <span>{todoData.completed.length} Completed</span>
              <br />
              <span>{todoData.cancelled.length} Cancelled</span>
            </>
          ) : (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        </Card.Text>
        <Card.Text>
          <span className="fw-bold m-0">Notes </span>
          <FontAwesomeIcon
            icon={notesOpen ? faChevronDown : faChevronUp}
            onClick={() => setNotesOpen(!notesOpen)}
            style={{ cursor: 'pointer' }}
            className="ps-3"
          />
          <FontAwesomeIcon
            icon={addNote ? faMinus : faPlus}
            onClick={() => setAddNote(!addNote)}
            style={{ cursor: 'pointer' }}
            className="ps-3"
          />
          {addNote && (
            <div>
              <InputGroup className="my-3">
                <Form.Control
                  placeholder="New note"
                  aria-label="New Note"
                  aria-describedby="basic-addon2"
                  as="textarea" rows={2}
                  value={noteText}
                  onChange={(e) => setNoteText(e.target.value)}
                />
                <Button variant="primary" id="button-addon2" onClick={saveNote}>
                  Save
                </Button>
              </InputGroup>
            </div>
          )}
          {notesOpen && (
            <>
              {notes.length > 0 ? (
                <>
                  {notes.map((note, i) => (
                    <div key={i} className="border">
                      <span className="fs-5">{note.fields.note}</span>
                      <br />
                      <span className="fs-7">
                        {dateFormat(note.fields.createdAt)} by{' '}
                        {note.fields.assistantName
                          ? note.fields.assistantName
                          : 'Admin'}
                      </span>
                    </div>
                  ))}
                </>
              ) : (
                <div className="fs-6">No notes</div>
              )}
            </>
          )}
        </Card.Text>
      </Card.Body>
      <HoursModal
        customer={cardCustomer}
        showModal={showHoursModal}
        setShowModal={setShowHoursModal}
      />
      <SubscriptionsModal
        customer={cardCustomer}
        showModal={showSubscriptionsModal}
        setShowModal={setShowSubscriptionsModal}
      />
      <TodosModal
        customer={cardCustomer}
        todoData={todoData}
        showModal={showTodosModal}
        setShowModal={setShowTodosModal}
      />
    </Card>
  );

}

export default CustomerCard;