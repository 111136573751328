import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, browserSessionPersistence, signOut } from 'firebase/auth';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp (firebaseConfig);

const FirebaseContext = createContext();
export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseContextProvider = ({ children }) => {
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [authLoaded, setAuthLoaded] = useState(false);
  const auth = getAuth(app);
  const firestore = getFirestore(app)
  const loginUser = async (email, password) => {
    await auth.setPersistence(browserSessionPersistence)
    signInWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        Bugsnag.notify(error)
        console.log(error)
      });
  }

  const logoutUser = async () => {
    await signOut(auth);
  }


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setAuthLoaded(true);
      if (user) {
        setFirebaseUser(user);
      } else {
        setFirebaseUser(null);
      }
    }
    );
  }, []);

  return (
    <FirebaseContext.Provider value={{ firebaseUser, loginUser, logoutUser, authLoaded, firestore }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export default app;