import { Modal } from "react-bootstrap";
import CustomerSearch from "./CustomerSearch";


const ChatModal = ({
  showModal,
  setShowModal,
  addChat
}) => {
  return (
    <Modal show={showModal} className="m-4" onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{"Add Admin <-> Client Chat"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomerSearch addChat={addChat}/>
      </Modal.Body>
    </Modal>
  );
}

export default ChatModal;